import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import * as yup from "yup";

import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import { useNavigate } from "react-router-dom";

import {
  ErrorMessage,
  Layout,
  NavigationButtons,
  ScreenTitle,
  TextInput,
} from "components";
import { errorMessages } from "config";
import { useAuth } from "hooks";

import { Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "state";
import { color_theme } from "../../color-theme";
import { texts } from "./constants";

const schema = yup
  .object({
    code: yup
      .string()
      .required(errorMessages.codeRequired)
      .min(4, errorMessages.codeMinLength),
  })
  .required();

const useStyles = makeStyles({
  link: {
    marginLeft: 4,
    color: color_theme.text.black.main,
  },
});

const ConfirmationCode = () => {
  const classes = useStyles();
  const { loading, codeVerify } = useAuth();
  const [error, setError] = useState("");
  const email = useSelector((state: RootState) => state.user.email);
  const numberLastDigits = useSelector(
    (state: RootState) => state.user.numberLastDigits
  );
  const loginMode = useSelector((state: RootState) => state.user.loginMode);
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = async (data: any) => {
    const res = {
      code: data.code,
      email: email,
    };
    const statusCode = await codeVerify(res);
    statusCode.messag === "failed to verify code"
      ? setError("Digite um código válido")
      : // @ts-ignore
        navigate(state ? state.nextRoute : "/");
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <Layout>
        <Box
          sx={{
            mt: 1,
            maxHeight: 700,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <Box width="100%">
            <ScreenTitle>
              {loginMode === "email"
                ? texts.loginTitleEmail
                : texts.loginTitleSMS}
            </ScreenTitle>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 300,
              }}
            >
              enviado para:{" "}
              {loginMode === "email"
                ? email
                : `XXXXX-${numberLastDigits || ""}`}
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 300,
                marginTop: 1,
              }}
            >
              caso não tenha recebido o código de acesso, verifique sua caixa de
              spam ou
              {email === "email"
                ? " lixo eletrônico."
                : " seu sinal de celular."}
            </Typography>
            <ErrorMessage text={error} />

            <TextInput
              type="number"
              control={control}
              error={errors["code"]}
              name="code"
              placeholder={texts.codePlaceholder}
              required
              autoComplete="off"
            />
            <Box mt={4} justifyContent="center">
              {texts.noAccount}
              <Link to="/signup" className={classes.link}>
                {texts.noAccountLink}
              </Link>
            </Box>
          </Box>
        </Box>
      </Layout>
      <NavigationButtons
        rightTitle={texts.login}
        rightProps={{ type: "submit" }}
        rightLoading={loading}
      />
    </form>
  );
};

export default ConfirmationCode;
