import { FUMICO_PHONE_NUMBER } from "config";

export const texts = {
  loginTitle: "seu email",
  loginMode: "receber código em",
  login: "Avançar",
  emailPlaceholder: "seu@gmail.com",
  modePlaceholder: "selecione o modo de login",
  noAccount: "não tem uma conta?",
  noAccountLink: "faça o cadastro aqui",
  autoRegister: {
    noAccount: "não tem uma conta?",
    noAccountLink: "faça o cadastro aqui",
  },
  inveteRegister: {
    noAccount: "*não tem uma conta? peça para um amigo indicar.",
    remember: "*mudou seu e-mail ou telefone?",
    rememberLink: "atualize aqui",
    whatsappUrl: `https://api.whatsapp.com/send?phone=${FUMICO_PHONE_NUMBER}&text=`,
    whatsappMessage: "olá! preciso de ajuda para recuperar minha conta.",
  },
};
