import { makeStyles } from "@mui/styles";
import closeIcon from "assets/closeIcon.svg";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { color_theme } from "../../color-theme";
import ErrorMessage from "../ErrorMessage";

const useStyles = makeStyles({
  container: {
    color: color_theme.text.black.main,
    fontWeight: 300,
    marginBottom: "26px",
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
    "& fieldset": {
      border: 0,
      borderRadius: 0,
      borderBottom: "1px solid black",
    },
  },
  clearButton: {
    position: "absolute",
    right: "32px",
    bottom: "12px",
    cursor: "pointer",
  },
  clearIcon: {
    padding: "12px",
    width: "36px",
  },
});

const SelectInput = ({
  control,
  error,
  options = [],
  includeClearButton,
  afterDelay,
  ...props
}: any) => {
  const classes = useStyles();

  const [timer, setTimer] = useState<any>(null);

  const onChangeHandler = (value: string, onChange: (v: string) => void) => {
    if (!value) {
      onChange(value);
      return;
    }

    if (afterDelay) {
      clearTimeout(timer);

      onChange(value);

      const newTimer = setTimeout(() => {
        afterDelay(value);
      }, 500);

      setTimer(newTimer);
      return;
    }

    onChange(value);
  };

  return control ? (
    <>
      <Controller
        name={props.name}
        control={control}
        render={({ field: { onChange, value, ...field } }) => (
          <FormControl fullWidth margin="normal" className={classes.container}>
            <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
            <Select
              labelId={`${props.name}-label`}
              id={props.name}
              value={value || ""}
              onChange={(e) =>
                onChangeHandler(String(e.target.value), onChange)
              }
              {...props}
              {...field}
            >
              {options.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <ErrorMessage text={error?.message} />
    </>
  ) : (
    <>
      <FormControl fullWidth margin="normal" className={classes.container}>
        <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
        <Select
          labelId={`${props.name}-label`}
          id={props.name}
          value={props.value || ""}
          onChange={(e) =>
            onChangeHandler(String(e.target.value), props.onChange)
          }
          {...props}
        >
          {options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {includeClearButton && props.value && (
        <span
          className={classes.clearButton}
          onClick={() => props.onChange({ target: { value: "" } })}
        >
          <img src={closeIcon} alt="clear" className={classes.clearIcon} />
        </span>
      )}
    </>
  );
};

export default SelectInput;
