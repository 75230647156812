import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  id?: string;
  email?: string;
  name?: string;
  missing_info?: boolean;
  phone?: string;
  numberLastDigits?: string;
  cpf?: string;
  accepted_terms_of_use: boolean;
  finished_orders_quantity?: number;
  is_underage?: boolean;
  cpf_invalid?: boolean;
  cpf_verified_at?: string;
  loginMode?: "sms" | "email";
}

const initialState: InitialState = {
  id: undefined,
  email: undefined,
  name: undefined,
  missing_info: undefined,
  phone: undefined,
  cpf: undefined,
  accepted_terms_of_use: false,
  numberLastDigits: undefined,
  loginMode: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (_, action) => action.payload,
    setUserNumber: (state, action) => {
      state.phone = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setLoginMode: (state, action) => {
      state.loginMode = action.payload;
    },
    confirmTermsOfUse: (state) => {
      state.accepted_terms_of_use = true;
    },
    setNumberLastDigits: (state, action) => {
      state.numberLastDigits = action.payload;
    },
  },
});

export const { actions, reducer } = userSlice;
